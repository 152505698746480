<template>
  <v-container class="pa-0">
    <v-card
      class="d-flex flex-column text-center mx-auto my-auto align-center justify-center"
      flat
      color="transparent"
      :height="$vuetify.breakpoint.mdAndUp ? '60vh' : '70vh'"
    >
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
      <v-card-title class="justify-center font-weight-bold title">
        잠시만 기다려주세요.
      </v-card-title>
      <v-card-subtitle> 보안을 위해 브라우저를 확인 중입니다 </v-card-subtitle>
    </v-card>
  </v-container>
</template>
<script>
import API from "@/API/auth";
/* eslint-disable no-unused-vars */
if (!window.Kakao.isInitialized()) {
  window.Kakao.init(process.env.VUE_APP_KAKAO_APP_KEY_WEB);
  window.Kakao.isInitialized();
}

export default {
  data() {
    return {
      token: "",
    };
  },
  async mounted() {
    return new Promise((resolve, reject) => {
      const kakaoAuthCode = this.$route.query.code;

      if (kakaoAuthCode) {
        API.signInWithKakao(kakaoAuthCode, this.$route.query.state);
      } else {
        alert("잘못된 방법으로 접근하셨습니다. 로그인 페이지로 이동합니다.");
        this.$router.push({ name: "Login" });
      }
    });
  },
  methods: {},
};
</script>
